import React from 'react'
import Merchandise from '../Components/Merchandise/Merchandise'

const MerchandisePage = () => {
  return (
    <div>
      <Merchandise />
    </div>
  )
}

export default MerchandisePage