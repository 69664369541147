import React from 'react';
import GalleryCom from '../Components/Gallery/Gallery';

const Gallery = () => {
    return (
        <div>
            <GalleryCom />

        </div>
    )
}

export default Gallery